.fade-in-section {
  opacity: 0;
  transform: translateY(80px);
  visibility: hidden;
  transition: opacity 0.5s ease-out, transform 1s ease-out;
  will-change: opacity, visibility;
  overflow-x: hidden;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: translateY(0px);
  visibility: visible;
}
