.flex {
  display: flex;
  flex-flow: row wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-direction-row {
  flex-direction: row;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-align-start {
  align-items: flex-start;
}

.flex-align-end {
  align-items: flex-end;
}

.flex-align-center {
  align-items: center;
}

.flex-align-stretch {
  align-items: stretch;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-start {
  justify-content: flex-start;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-justify-space-between {
  justify-content: space-between;
}

.flex-justify-space-around {
  justify-content: space-around;
}

.flex-justify-space-evenly {
  justify-content: space-evenly;
}

.col {
  flex: 1;
}

.col-auto {
  flex: 0 1 auto;
}

.order-first {
  order: -9999;
}

.order-last {
  order: 9999;
}

$flex-columns: 12;

@for $i from 1 through 12 {
  .col-#{$i} {
    flex: 0 0 calc(($i/12) * 100%);
    max-width: calc(($i/12) * 100%);
  }

  .gap-#{$i} {
    gap: calc($i * $base-offset);
  }

  .gap-row-#{$i} {
    row-gap: calc($i * $base-offset);
  }

  .gap-column-#{$i} {
    column-gap: calc($i * $base-offset);
  }
}

.full-width {
  width: 100%;
}

.display-inline {
  display: block;
}

.display-inline-block {
  display: block;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.display-initial {
  display: initial;
}

@each $resolution in $resolutions {
  @include flexgrid-break($resolution) {
    .flex-#{$resolution} {
      flex: 0 1 auto;
    }

    .col-auto-#{$resolution} {
      flex: 0 1 auto;
    }

    .flex-nowrap-#{$resolution} {
      flex-wrap: nowrap;
    }

    .flex-wrap-#{$resolution} {
      flex-wrap: wrap;
    }

    .flex-direction-row-#{$resolution} {
      flex-direction: row;
    }

    .flex-direction-column-#{$resolution} {
      flex-direction: column;
    }

    .flex-align-start-#{$resolution} {
      align-items: flex-start;
    }

    .flex-align-end-#{$resolution} {
      align-items: flex-end;
    }

    .flex-align-center-#{$resolution} {
      align-items: center;
    }

    .flex-align-stretch-#{$resolution} {
      align-items: stretch;
    }

    .flex-justify-center-#{$resolution} {
      justify-content: center;
    }

    .flex-justify-start-#{$resolution} {
      justify-content: flex-start;
    }

    .flex-justify-end-#{$resolution} {
      justify-content: flex-end;
    }

    .flex-justify-space-between-#{$resolution} {
      justify-content: space-between;
    }

    .flex-justify-space-around-#{$resolution} {
      justify-content: space-around;
    }

    .flex-justify-space-evenly-#{$resolution} {
      justify-content: space-evenly;
    }

    .order-first-#{$resolution} {
      order: -9999;
    }

    .order-last-#{$resolution} {
      order: 9999;
    }

    .display-inline-#{$resolution} {
      display: block;
    }

    .display-inline-block-#{$resolution} {
      display: block;
    }

    .display-block-#{$resolution} {
      display: block;
    }

    .display-none-#{$resolution} {
      display: none;
    }

    .display-initial-#{$resolution} {
      display: initial;
    }

    .hidden-#{$resolution} {
      display: none !important;
    }

    .full-width-#{$resolution} {
      width: 100%;
    }

    @for $i from 1 through 12 {
      .col-#{$i}-#{$resolution} {
        flex: 0 0 calc(($i/12) * 100%);
        max-width: calc(($i/12) * 100%);
      }

      .gap-#{$i}-#{$resolution} {
        gap: calc($i * $base-offset);
      }

      .gap-row-#{$i}-#{$resolution} {
        row-gap: calc($i * $base-offset);
      }

      .gap-column-#{$i}-#{$resolution} {
        column-gap: calc($i * $base-offset);
      }
    }
  }
}
