$directions: "top" "bottom" "left" "right";
$multipliers: 0.5, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12;

@for $i from 1 through length($multipliers) {
  .m-#{$i} {
    margin: ceil($base-offset * nth($multipliers, $i));
  }

  .-m-#{$i - 1} {
    margin: ceil(-$base-offset * nth($multipliers, $i));
  }

  .p-#{$i - 1} {
    padding: ceil($base-offset * nth($multipliers, $i));
  }

  @each $direction in $directions {
    .m-#{$direction}-#{$i - 1} {
      margin-#{$direction}: ceil($base-offset * nth($multipliers, $i));
    }

    .p-#{$direction}-#{$i - 1} {
      padding-#{$direction}: ceil($base-offset * nth($multipliers, $i));
    }
  }
}

@each $resolution in $resolutions {
  @include flexgrid-break($resolution) {
    .m-auto-#{$resolution} {
      margin-left: auto;
      margin-right: auto;
    }

    @for $i from 1 through length($multipliers) {
      .m-#{$i - 1}-#{$resolution} {
        margin: ceil($base-offset * nth($multipliers, $i));
      }

      .p-#{$i - 1}-#{$resolution} {
        padding: ceil($base-offset * nth($multipliers, $i));
      }

      @each $direction in $directions {
        .m-#{$direction}-#{$i - 1}-#{$resolution} {
          margin-#{$direction}: ceil($base-offset * nth($multipliers, $i));
        }

        .p-#{$direction}-#{$i - 1}-#{$resolution} {
          padding-#{$direction}: ceil($base-offset * nth($multipliers, $i));
        }
      }
    }

    @each $direction in $directions {
      .m-#{$direction}-no-#{$resolution} {
        margin-#{$direction}: 0;
      }

      .m-#{$direction}-auto-#{$resolution} {
        margin-#{$direction}: auto;
      }

      .p-#{$direction}-no-#{$resolution} {
        padding-#{$direction}: 0;
      }
    }

    .m-no-#{$resolution} {
      margin: 0;
    }

    .p-no-#{$resolution} {
      padding: 0;
    }
  }
}

.m-auto {
  margin: auto;
}

.m-no {
  margin: 0px;
}

.p-no {
  padding: 0px;
}

@each $direction in $directions {
  .m-#{$direction}-auto {
    margin-#{$direction}: auto;
  }

  .m-#{$direction}-no {
    margin-#{$direction}: 0;
  }

  .p-#{$direction}-no {
    padding-#{$direction}: 0;
  }
}
