@import "../../styles/variables.scss";

#rozi-and-moni {
  .our-image {
    width: 100%;
    height: auto;
    max-width: 300px;
  }

  .our-image-container {
    margin-top: 0px;
    margin-left: -24px;
    position: sticky;
    top: 0px;
    align-self: flex-start;

    @media (max-width: $screen-sm) {
      width: 98%;
      margin-left: -16px;
      margin-top: 48px;
      top: 16px;
    }
  }

  .our-image-footer {
    width: 100px;
    height: auto;
    margin: 16px auto;
    cursor: pointer;
    transition-duration: 0.8s;
    transition-property: transform;

    &:hover {
      transform: rotate(10deg);
    }
  }

  .bride-steal {
    &--bg {
      background: #E4DCD5;
    }

    img {
      width: 100%;
      max-width: 600px;
      transform: none;
    }
  }

  .church {
    &--bg {
      background: #AABFA6;
    }
  }

  .slider div {
    border-radius: 16px;
  }

  .slider>div {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .garden {
    &--bg {
      background: #CEC1C4;
    }
  }

  .restaurant {
    &--bg {
      background: #c48b67;
    }
  }

  .transport {
    &--bg {
      background: #e9eae0;
    }
  }

  .map-location {
    border-radius: 8px;
    overflow: hidden;
  }

  .bulgarian-rose {
    width: 100px;
    height: auto;
    position: absolute;
    z-index: 11;
    bottom: -$base-offset * 4;
    right: -$base-offset * 4;

    @media (max-width: $screen-sm) {
      width: 60px;
      height: auto;
      right: -14px;
      bottom: -$base-offset * 3;
    }
  }

  .slider-mobile {
    @media (max-width: $screen-xs) {
      overflow-x: hidden;
    }
  }


  .bg-blue-light {
    background-color: #A7B6CE;
    box-shadow: inset #5275A3 0px 0px 12px;
  }
}