@import "../../styles/variables.scss";

#desi-and-viktor {
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  z-index: 0;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: "";
  position: absolute;
  width: 6px;
  background-color: var(--secondary-color);
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
  z-index: -1;
}

/* Container around content */
.timeline-container {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;

  @media screen and (min-width: $screen-sm) {
    width: 50%;
  }
}

/* The circles on the timeline */
.timeline-container::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  right: -17px;
  background-color: white;
  border: 4px solid var(--primary-color);
  top: 15px;
  border-radius: 50%;
  z-index: 3;
}

/* Place the container to the left */
.left {
  left: 0;
}

/* Place the container to the right */
.right {
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
.left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 3;
  right: 30px;
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent white;
}

/* Add arrows to the right container (pointing left) */
.right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 3;
  left: 30px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
}

/* Fix the circle for containers on the right side */
.right::after {
  left: -16px;
}

/* The actual content */
.content {
  padding: 20px 30px;
  background-color: white;
  position: relative;
  border-radius: 6px;
}

.family-photo {
  width: 100%;
  border-radius: 5px;
}

.timeline-clock {
  position: absolute;
  top: 27px;
  z-index: 200;
}

.left {
  .timeline-clock {
    right: -7px;

    @media (max-width: $screen-sm) {
      left: 24.8px;
    }
  }
}

.right {
  .timeline-clock {
    left: -6px;

    @media (max-width: $screen-sm) {
      left: 24.8px;
    }
  }
}

.timeline-arrow-minutes {
  border-radius: 5px;
  width: 13px;
  height: 3px;
  background: black;
  position: relative;
  z-index: 200;
}

.timeline-arrow-minutes-0 {
  transform: rotate(-90deg);
  left: -0.2px;
  top: -1px;
}

.timeline-arrow-minutes-30 {
  transform: rotate(-90deg);
  left: -0.2px;
  top: 8px;
}

.timeline-arrow-minutes-45 {
  transform: rotate(0deg);
  left: -5.2px;
  top: 3.4px;
}


.timeline-clock-circle {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  position: relative;
  top: 50%;
  transform: translateX(-50%);
  right: -6.4px;
  z-index: 202;
  background: #a24f5c;
}

.timeline-arrow-hour {
  border-radius: 5px;
  width: 11px;
  height: 3px;
  background: var(--hover-color);
  position: relative;
  z-index: 201;
}

.timeline-arrow-hour-14 {
  transform: rotate(-33deg);
  right: -4px;
  top: -5.4px;
}

.timeline-arrow-hour-15 {
  right: -5px;
  top: -4px;
}

.timeline-arrow-hour-16 {
  transform: rotate(33deg);
  right: -4.4px;
  top: -2px;
}

.timeline-arrow-hour-17 {
  transform: rotate(66deg);
  right: -2.4px;
  top: 0px;
}

.timeline-arrow-hour-19 {
  transform: rotate(123deg);
  right: 1.5px;
  top: 0.2px;
}

.timeline-arrow-hour-20 {
  transform: rotate(150deg);
  right: 2.2px;
  top: -2px;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: $screen-sm) {
  /* Place the timelime to the left */
  .timeline::after {
    left: 31px;
  }

  /* Full-width containers */
  .timeline-container {
    max-width: 100%;
    width: auto;
    padding-left: 70px;
    padding-right: 16px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .timeline-container::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left::after,
  .right::after {
    left: 15px;
  }

  /* Make all right containers behave like the left ones */
  .right {
    left: 0%;
  }
}
}
