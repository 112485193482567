@import "../../styles/variables.scss";

#desi-and-viktor {
  .bg-fold {
    background-color: white;
    background-image: url("./images/bg-fold-clouds.webp");
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: contain;

    @media screen and (max-width: $screen-md) {
      background-size: cover;
    }
  }

  .bg-fold-left {
    position: absolute;
    left: 0;
    top: -66px;
    width: 40%;
    max-width: 700px;
    height: auto;

    @media screen and (max-width: $screen-xs) {
      top: 0px;
      width: 48%;
    }
  }

  .bg-fold-left-down {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 20%;
    max-width: 600px;
    height: auto;

    @media screen and (max-width: $screen-xs) {
      width: 40%;
    }
  }

  .bg-fold-right {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 40%;
    max-width: 700px;
    height: auto;

    @media screen and (max-width: $screen-xs) {
      width: 48%;
    }
  }


  .text-fold {
    background: linear-gradient(-90deg, #00dffc 5px, #0000 0) 10px 0,
      linear-gradient(#00dffc 0 0) 0 0;
    background-size: calc(var(--n) * 1ch) 200%;
    -webkit-background-clip: padding-box, text;
    background-clip: padding-box, text;
    background-repeat: no-repeat;
    animation: b 0.7s infinite steps(1),
      t calc(var(--n) * 0.3s) steps(var(--n)) forwards;
  }

  .fold {
    min-height: 600px;
  }
}