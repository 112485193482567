// Base Offset

//TODO: the left const might not be need
:root {
    --border-color: #e0e0e0;

    // Default Colors Palette
    --primary-color: #a24f5c;
    --secondary-color: #452b39;
    --third-color: #452b39;
    --hover-color: #c48b67;
    --base-color: #f0ebec;
    --link-color: #754c50;

    --text-color: #384347;
    --accent-color: #e3e9f5;
    --info-color: #3f9cdc;
    --warning-color: #feb251;
    --danger-color: #ff576f;
    --white-color: #ffffff;

    // Backgrounds
    --bg-page-color: #f8f8f8;

    --surface-color: var(--white-color);
    --surface-accent-color: #9cb2dc;
    --bg-page-alt-color: #fafbfd;
     
}

$base-offset: 8px;

// Brakepoints:
$screen-xs: 480px; // Extra small screen / phone
$screen-sm: 768px; // Small screen / tablet
$screen-md: 992px; // Medium screen / desktop
$screen-lg: 1200px; // Large screen / wide desktop
$screen-xl: 1400px; // Large screen / wide desktop

$resolutions: "xs", "sm", "md", "lg", "xl";


// Colors

$border-color: #e0e0e0;

// // Default Colors Palette
$primary: #a24f5c;
$secondary: #452b39;
$third: #452b39;
$hover: #c48b67;
$base: #f0ebec;
$link: #754c50;

$text: #384347;
$accent: #e3e9f5;
$info: #3f9cdc;
$warning: #feb251;
$danger: #ff576f;
$white: #ffffff;

// // Backgrounds
$bg-page: #f8f8f8;

$surface: $white;
$surface-accent: #9cb2dc;
$bg-page-alt: #fafbfd;

// // Typography Colors
$headings: #2d3639;
$paragraphs: #384347;
$labels: #60696c;
$captions: #afb4b5;
$subtexts: #d8d9db;

// Font-sizes
$font-size-xl: 26px;
$font-size-lg: 20px;
$font-size-md: 18px;
$font-size-sm: 16px;
$font-size-xs: 14px;
$font-size-xs-min: 12px;
