#gizi-and-dido {
.godparents {
  background: white;
}

.godparents-photo {
  width: 100%;
  max-width: 600px;
}

.godparents-photo-container {
  margin: 0 auto -68px auto;
}

.evelin-text {
  position: absolute;
  bottom: 8px;
  left: 8px;
}

.nikolai-text {
  position: absolute;
  bottom: 8px;
  right: 8px;
  z-index: 1;
}

.evelin-text,
.nikolai-text {
  padding: 0px 16px;
  background: #ffffff96;
  border-radius: 5px;
}
}
