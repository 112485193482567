@import "../styles/variables.scss";

#gizi-and-dido {

.parallax {
  color: aliceblue;
}

.parallax-inner {
  padding-top: 12%;
  padding-bottom: 12%;
}

.parallax-engaged {
  &:after {
    content: "";
    position: fixed;
    top: 0;
    height: 100vh;
    left: 0;
    right: 0;
    z-index: -1;
    background: url("./images/bg-engaged5.webp") center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    @media screen and (max-width: $screen-sm) {
      background: url("./images/bg-engaged5-mobile.webp") center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
  }
}

.parallax-arbanasi {
  &:after {
    content: "";
    position: fixed;
    top: 0;
    height: 100vh;
    left: 0;
    right: 0;
    z-index: -2;
    background: url("./images/bg-arbanasi.webp") center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    @media screen and (max-width: $screen-sm) {
      background: url("./images/bg-arbanasi-mobile.webp") center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
  }
}

.parallax-rilski-ezera {
  &:after {
    content: "";
    position: fixed;
    top: 0;
    height: 100vh;
    left: 0;
    right: 0;
    z-index: -3;
    background: url("./images/rilskiezera.webp") center bottom;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 15%;
    padding-bottom: 15%;

    @media screen and (max-width: $screen-sm) {
      background: url("./images/rilskiezera-mobile.webp") bottom center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
  }

  .parallax-inner {
    padding-top: 15%;
    padding-bottom: 15%;
  }
}
}
