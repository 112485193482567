@import "../../styles/variables.scss";

#rozi-and-moni {
  .parallax {
    color: aliceblue;
    overflow: hidden;
  }

  .parallax-inner {
    padding-top: 12%;
    padding-bottom: 12%;
  }

  .parallax-engaged {
    &:after {
      content: "";
      position: fixed;
      top: 0;
      height: 100vh;
      left: 0;
      right: 0;
      z-index: -1;
      background: url("./images/bg-main-1.webp") top center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
  }

  .parallax-arbanasi {
    &:after {
      content: "";
      position: fixed;
      top: 0;
      height: 100vh;
      left: 0;
      right: 0;
      z-index: -2;
      background: url("./images/bg-main-2.webp") top center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
  }

  .parallax-rilski-ezera {
    &:after {
      content: "";
      position: fixed;
      top: 0;
      height: 100vh;
      left: 0;
      right: 0;
      z-index: -3;
      background: url("./images/bg-main-3.webp") top center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      padding-top: 0%;
      padding-bottom: 15%;

      @media screen and (max-width: $screen-sm) {
        background: url("./images/bg-main-3.webp") center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
      }
    }

    .parallax-inner {
      padding-top: 15%;
      padding-bottom: 15%;
    }
  }
}