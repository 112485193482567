@import "../styles/variables.scss";

#gizi-and-dido {
  .modern-form {
    width: 100%;
    background-color: #fff;
    border: solid 1px #452B38;
    border-radius: 12px;
  }

  .modern-form h3 {
    text-align: center;
  }

  .float-label-field {
    border: none;
    outline: none;
    position: relative;
    margin: 0 0 32px 0;
    padding: 0;
    box-sizing: border-box;
  }

  .float-label-field input {
    border: none;
    outline: none;
    padding: 8px 5px 8px 0px;
    width: 100%;
    font-size: 20px;
    border-bottom: solid 1px #452B38;
  }

  .float-label-field label {
    opacity: 0.5;
    position: absolute;
    top: 10px;
    left: 0px;
    transition: all 0.2s ease;
    font-size: 20px;
  }

  .float-label-field.focus label {
    color: #452B38;
  }

  .float-label-field.focus input {
    border-bottom: solid 1px #452B38;
  }

  .float-label-field.float label {
    opacity: 1;
    top: -8px;
    font-size: 60%;
    transition: all 0.2s ease;
    font-weight: bold;
  }

  ul {
    list-style: none;
    margin: 0 0 20px;
    padding: 0;
  }

  li:not(:last-child) {
    margin-bottom: $base-offset;
  }

  input[type="radio"] {
    margin: 0 12px 0 0;
    accent-color: #452B38;
    transform: scale(1.6);
    cursor: pointer;
    background: -apple-system-control-accent;
  }

  //CSS Loader bar

  .lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    transform: scale(0.3);
  }

  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }

  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #fff;
    margin: -4px 0 0 -4px;
  }

  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }

  .lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }

  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }

  .lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }

  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }

  .lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }

  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }

  .lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }

  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }

  .lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }

  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }

  .lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }

  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }

  .lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }

  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }

  .lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }

  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .loading {
    position: absolute;
    z-index: 1;
    top: -16px;
    right: -24px;
  }

  .bulgarian-rose-form {
    width: 120px;
    position: absolute;
    z-index: 11;
    top: -42px;
    right: -33px;

    @media (max-width: $screen-sm) {
      width: 100px;
      top: -34px;
      right: -14px;
    }
  }
}