@import "../../styles/variables.scss";

@keyframes slideFromTop {
  0% {
    top: -200px;
  }

  100% {
    top: 0;
  }
}

#rozi-and-moni {
  .svatbena-pokana-title {
    font-size: 21px;
    color: var(--link-color);
    position: absolute;
    font-weight: 500;
    top: $base-offset * 2;
    left: $base-offset * 3;
  }

  .navbar {
    z-index: 2000;
    position: relative;
    padding-top: $base-offset * 1;
    padding-right: $base-offset * 1;

    &-container {
      padding: 0;
      display: flex;

      li {
        list-style-type: none;
        padding: 10px 20px;
      }

      a {
        text-decoration: none;
        text-transform: uppercase;
        font-size: 18px;
        color: var(--link-color);
        position: relative;
      }

      li a::before {
        content: "";
        width: 0;
        height: 5px;
        background-color: var(--primary-color);
        position: absolute;
        top: 120%;
        left: 0;
        transition: all 0.5s;
      }

      li a::after {
        content: "";
        width: 0;
        height: 5px;
        background-color: var(--secondary-color);
        position: absolute;
        top: 120%;
        right: 0;
        transition: all 0.5s;
      }

      li a:hover::before {
        width: 50%;
        transform: translateX(100%);
      }

      li a:hover::after {
        width: 50%;
        transform: translateX(-100%);
      }
    }
  }

  .sandwich-menu {
    font-size: 24px;
    top: $base-offset * 2;
    right: $base-offset * 3;
    position: absolute;
    color: var(--link-color);
    z-index: 1999;

    &-content {
      position: absolute;
      z-index: 2000;
      top: 0px;
      right: 0px;
      background: var(--secondary-color);
      animation-name: slideFromTop;
      animation-duration: 1s;
      animation-fill-mode: forwards;

      hr {
        border-top: 1px solide var(--link-color);
      }

      a {
        color: white;
        text-decoration: none;
        padding: 16px;
      }
    }

    &-opened {
      color: white;
      z-index: 2000;
    }
  }
}