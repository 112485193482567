//TODO: check load of Rubik and Inter

@import "./variables.scss";
@import "./mixins.scss";
@import "./grid.scss";
@import "./offsets.scss";
@import "./typography.scss";

@keyframes t {
  from {
    background-size: 0 200%;
  }
}
@keyframes b {
  50% {
    background-position: 0 -100%, 0 0;
  }
}

html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Inter", "Helvetica", "Arial", sans-serif;
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  background: $bg-page;
  font-size: $font-size-md;
}

.relative {
  position: relative;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// Container
$container-sm: calc($screen-sm - $base-offset * 4);
$container-md: calc($screen-md - $base-offset * 4);
$container-lg: calc($screen-lg - $base-offset * 4);
$container-xl: calc($screen-xl - $base-offset * 4);

.container {
  width: 100%;
  padding-right: $base-offset * 2;
  padding-left: $base-offset * 2;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: $screen-sm) {
    width: $container-sm;
  }
  @media (min-width: $screen-md) {
    width: $container-md;
  }
  @media (min-width: $screen-lg) {
    width: $container-lg;
  }

  @media (min-width: $screen-xl) {
    width: $container-xl;
  }
}

// // EFFECTS - SHADOWS
.shadow-default {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.shadow-hover {
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
}

.shadow-flat {
  box-shadow: 1px 1px 1px rgba(231, 228, 228, 0.5);
}

.shadow-main-navigation {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}

// // BORDERS

.border {
  border: 1px solid $border-color;
}

.border-rounded {
  border-radius: 50%;
}

.border-rounded-0 {
  border-radius: 4px;
}

.border-rounded-1 {
  border-radius: 8px;
}

// Cursors
.cursor-pointer {
  cursor: pointer;
}

// Backgrounds

.bg-base {
  background-color: var(--base-color);
}

.bg-page {
  background-color: $bg-page;
}

.bg-primary {
  background-color: var(--primary-color);
}

.bg-secondary {
  background-color: var(--secondary-color);
}

.bg-third {
  background-color: var(--third-color);
}

.bg-white {
  background-color: white;
}

// Buttons

.confirm-btn {
  background-color: var(--primary-color);
  border: none;
  color: white;
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 4px;

  &:hover {
    background-color: var(--secondary-color);
    border: 1px solid var(--primary-color);
    color: white;
  }
}

#rozi-and-moni {
  .confirm-btn {
    &:hover {
      border: 1px solid #8b7175;
    }
  } 
}

.confirm-btn-inverse {
  color: var(--primary-color);
  background-color: white;
  font-weight: bold;
  border: 1px solid var(--primary-color);
}

.hr-style {
  border-top: 2px solid var(--primary-color);
}

#rozi-and-moni {
  .hr-style {
    border-top: 2px solid var(--secondary-color);
  }
}
