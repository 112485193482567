#desi-and-viktor {
.godparents {
  background: var(--primary-color);
}

.godparents-photo {
  width: 100%;
  max-width: 500px;
  height: 100%;
}

.godparents-photo-container {
  margin: 40px auto -100px auto;
  background: var(--primary-color);
  border-radius: 5px;
}

.evelin-text {
  position: absolute;
  bottom: 8px;
  left: 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.nikolai-text {
  position: absolute;
  bottom: 8px;
  right: 0px;
  z-index: 1;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.evelin-text,
.nikolai-text {
  padding: 0px 16px;
  background: #ffffff96;
}
}
