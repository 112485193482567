// Font families
.font-heading {
  font-family: "Caveat", cursive;
}


.font-body {
  font-family: "Inter, sans-serif";
}

// Headings
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Caveat", cursive;
  font-style: normal;
  color: $headings;
  margin: 0;
  padding: 0;
}

h1,
.h1 {
  font-weight: 500;
  font-size: 68px;
  line-height: 82px;

  @media (max-width: $screen-xs) {
    font-size: 38px;
    line-height: 46px;
  }
}

h2,
.h2 {
  font-weight: 500;
  font-size: 48px;
  line-height: 56px;

  @media (max-width: $screen-xs) {
    font-size: 28px;
    line-height: 34px;
  }
}

h3,
.h3 {
  font-weight: 500;
  font-size: 38px;
  line-height: 46px;

  @media (max-width: $screen-xs) {
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
  }
}

h4,
.h4 {
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;

  @media (max-width: $screen-xs) {
    font-size: 22px;
    line-height: 24px;
  }
}

h5,
.h5 {
  font-weight: 400;
  font-size: 26px;
  line-height: 31px;

  @media (max-width: $screen-xs) {
    font-size: 20px;
    line-height: 22px;
  }
}

h6,
.h6 {
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;

  @media (max-width: $screen-xs) {
    font-size: 18px;
    line-height: 22px;
  }
}

// Content Texts

.text-xl {
  font-size: $font-size-xl;
}

.text-lg {
  font-size: $font-size-lg;
}

.text-md {
  font-size: $font-size-md;
}

.text-sm {
  font-size: $font-size-sm;
}

.text-xs {
  font-size: $font-size-xs;
}

.text-xs-min {
  font-size: $font-size-xs-min;
}

// Font Weights

.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

// Text alignment

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-primary {
  color: var(--primary-color);
}

.text-secondary {
  color: var(--secondary-color);
}

.text-third {
  color: var(--third-color);
}

.text-base {
  color: var(--base-color);
}

.text-hover {
  color: var(--hover-color);
}

.text-bold {
  font-weight: bold;
}

.text-white {
  color: var(--white-color);
}

// Typography Responsivness
@each $resolution in $resolutions {
  @include flexgrid-break($resolution) {

    // Content Texts
    .text-lg-#{$resolution} {
      font-size: $font-size-lg;
    }

    .text-md-#{$resolution} {
      font-size: $font-size-md;
    }

    .text-sm-#{$resolution} {
      font-size: $font-size-sm;
    }

    .text-xs-#{$resolution} {
      font-size: $font-size-xs;
    }

    .text-xs-min-#{$resolution} {
      font-size: $font-size-xs-min;
    }

    // Font Weights

    .font-weight-300-#{$resolution} {
      font-weight: 300;
    }

    .font-weight-400-#{$resolution} {
      font-weight: 400;
    }

    .font-weight-500-#{$resolution} {
      font-weight: 500;
    }

    .font-weight-600-#{$resolution} {
      font-weight: 600;
    }

    .font-weight-700-#{$resolution} {
      font-weight: 700;
    }

    // Text alignment

    .text-center-#{$resolution} {
      text-align: center;
    }

    .text-left-#{$resolution} {
      text-align: left;
    }

    .text-right-#{$resolution} {
      text-align: right;
    }
  }
}