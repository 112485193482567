@import "../styles/variables.scss";

#gizi-and-dido {
.box {
  position: relative;
  width: 280px;
  height: 280px;
  box-sizing: border-box;

  @media (max-width: $screen-xs) {
    height: auto;
  }
}

.text-nie {
  color: var(--primary-color);
  position: absolute;
  left: 42%;
  top: 0px;

  @media (max-width: $screen-xs) {
    position: relative;
    left: unset;
    top: unset;
    text-align: center;
  }
}

.box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-top: 4px solid var(--secondary-color);;
  border-bottom: 4px solid var(--secondary-color);;
  box-sizing: border-box;
  transition: 0.5s;
  transform: scaleX(0);

  @media (max-width: $screen-xs) {
    display: none;
  }
}

.box-active:before {
  transform: scaleX(1);

  @media (max-width: $screen-xs) {
    display: none;
  }
}

.box:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-right: 4px solid var(--secondary-color);
  border-left: 4px solid var(--secondary-color);
  box-sizing: border-box;
  transition: 0.5s;
  transform: scaleY(0);

  @media (max-width: $screen-xs) {
    display: none;
  }
}

.box-active:after {
  transform: scaleY(1);
  transition-delay: 0.5s;

  @media (max-width: $screen-xs) {
    display: none;
  }
}

.rotated {
  position: absolute;
  width: 90%;
  height: 90%;
  display: block;
  margin: 5%;
  transform: rotate(45deg);
  box-sizing: border-box;
}

.rotated:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-top: 4px solid var(--primary-color);
  border-bottom: 4px solid var(--primary-color);
  box-sizing: border-box;
  transition: 0.5s;
  transform: scaleX(0);
}

.box-active .rotated:before {
  transform: scaleX(1);
  transition-delay: 1s;
}

.rotated:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-right: 4px solid var(--primary-color);
  border-left: 4px solid var(--primary-color);
  box-sizing: border-box;
  transition: 0.5s;
  transform: scaleY(0);
}

.box-active .rotated:after {
  transform: scaleY(1);
  transition-delay: 1.5s;
}

.custom-margin-danail {
  margin-top: -20px;
}
}