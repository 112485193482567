@import "../../styles/variables.scss";

#rozi-and-moni {
  .bg-fold-gradient {
    background-color: #EDE4E4;
    background-image: linear-gradient(to right, #ffffff, #A7B7CE);
    min-height: 400px;

    //TODO: check with Rozi&Moni
    //background: linear-gradient(0.25turn, rgb(200, 170, 175), rgb(237, 229, 228), rgb(167, 184, 206));
    //background-image: linear-gradient(to right, #EDE4E4, #A7B7CE);
  }

  .fold-image-container {
    width: 100%; 
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: $screen-sm) {
      margin-top: -4%; 
    }
  }
}