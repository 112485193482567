@mixin flexgrid-break($resolution) {
  @if $resolution == xs {
    @media (max-width: calc($screen-xs)) {
      @content;
    }
  } @else if $resolution == sm {
    @media (min-width: calc($screen-xs + 1px)) and (max-width: $screen-sm) {
      @content;
    }
  } @else if $resolution == md {
    @media (min-width: calc($screen-sm + 1px)) and (max-width: $screen-md) {
      @content;
    }
  } @else if $resolution == lg {
    @media (min-width: calc($screen-md + 1px)) and (max-width: $screen-lg) {
      @content;
    }
  } @else if $resolution == xl {
    @media (min-width: $screen-lg) {
      @content;
    }
  }
}
