@import "../styles/variables.scss";

#gizi-and-dido {
.bg-fold {
  background-image: url("./images/bg-fold.webp");
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 600px;

  @media screen and (max-width: $screen-xs) {
    background-image: url("./images/bg-fold-mobile.webp");
  }
}

.text-fold {
  background: linear-gradient(-90deg, #00dffc 5px, #0000 0) 10px 0,
    linear-gradient(#00dffc 0 0) 0 0;
  background-size: calc(var(--n) * 1ch) 200%;
  -webkit-background-clip: padding-box, text;
  background-clip: padding-box, text;
  background-repeat: no-repeat;
  animation: b 0.7s infinite steps(1),
    t calc(var(--n) * 0.3s) steps(var(--n)) forwards;
}

.fold {
  min-height: 600px;
}
}
